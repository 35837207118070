import React, { useEffect, useState } from 'react';
import { getCohortsForCoach } from '../../collections/cohort';
import { useNavigate } from 'react-router-dom';
import { getCompanyById } from '../../collections/company';
import { getAccountsForCohort } from '../../collections/account';
import { Pages } from '../../enums/pages';
import { useAppContext } from '../../contexts/appContext';
import { getAllCoaches } from '../../collections/coach';
import Skeleton from 'react-loading-skeleton';
import SearchBar from '../SearchBar';
import { uiFormatTimestamp } from '../../collections/shared';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { CohortConfigType } from 'src/modeltypes/cohort';

const CohortList = () => {
  type TableData = {
    name?: string;
    company?: string;
    coaches?: string[];
    userNumber?: number;
    startDate?: string;
    id?: string;
    isTestCohort?: boolean;
    removeFromStatistics?: boolean;
    config?: CohortConfigType | null;
  };
  const [showTest, setShowTest] = useState(false);

  const { searchTerm, role, user } = useAppContext();

  const prepareTableData = async () => {
    const newTableData: TableData[] = [];
    const cohorts = await getCohortsForCoach(['admin', 'science'].includes(role || '') ? undefined : user?.uid);
    for (const cohort of cohorts) {
      const userNumber = (await getAccountsForCohort(cohort.id || '')).length;
      const data: TableData = {
        name: cohort.name,
        userNumber: userNumber,
        id: cohort.id,
        coaches: cohort.coach,
        startDate: uiFormatTimestamp(cohort.creationDate),
        isTestCohort: cohort?.isTestCohort,
        removeFromStatistics: cohort?.removeFromStatistics,
        config: cohort?.config || null,
      };
      const company = await getCompanyById(cohort.company || '');
      data.company = company?.companyName;

      newTableData.push(data);
    }
    return newTableData;
  };

  const { data: coaches, isLoading: isLoadingCoaches } = useQuery({
    queryFn: getAllCoaches,
    queryKey: ['coaches'],
  });

  const {
    data: tableData,
    error: cohortError,
    isLoading: isLoadingCohorts,
  } = useQuery({
    queryFn: prepareTableData,
    queryKey: [''],
    retry: 2,
  });

  const isLoading = isLoadingCoaches || isLoadingCohorts;

  console.log('tableData: ', tableData);

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!tableData?.length) return [];
    return tableData
      .filter((item) => showTest || (!showTest && !item.isTestCohort))
      .filter((item) => {
        const matchName = item.name && item.name.match(regex);
        const matchOrg = item.company && item.company.match(regex);
        const matchCoach = item.coaches && item.coaches.some((coach) => coach.match(regex));
        const matchId = item.id && item.id.match(regex);
        return matchName || matchId || matchOrg || matchCoach;
      });
  }, [searchTerm, tableData, showTest]);

  useEffect(() => {
    if (cohortError) {
      toast.error('Something went wrong while fetching cohorts. Trying again...');
    }
  }, [cohortError]);

  const navigate = useNavigate();

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <>
                <button className={'mb-2 font-medium'} onClick={() => setShowTest(!showTest)}>
                  {showTest ? 'Hide test cohorts' : 'Show test cohorts'}
                </button>
                <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          Id
                        </th>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          Cohort Name
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Company
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Coach
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Number of Users
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Is Test Cohort
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Exclude From Statistics
                        </th>

                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Accel On
                        </th>

                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Gryo On
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Audio On
                        </th>
                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Show Charts
                        </th>

                        <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Start date
                        </th>
                        <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                          <span className='sr-only'>Stats</span>
                        </th>
                        <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                          <span className='sr-only'>Stats</span>
                        </th>
                        <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                          <span className='sr-only'>Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className={`divide-y divide-gray-200 bg-white`}>
                      {filteredItems.map((cohort) => (
                        <tr
                          key={cohort.id}
                          className={`hover:bg-stone-200 ${cohort.isTestCohort ? 'bg-green-100' : ''}`}
                        >
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{cohort.id}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>
                                  {cohort.isTestCohort ? '[TEST]' : ''} {cohort.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='font-medium text-gray-900'>{cohort.company}</div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {cohort.coaches && cohort.coaches?.length > 0
                              ? cohort.coaches?.map((coachId) => (
                                  <div key={coachId} className='text-gray-500'>
                                    {coaches?.find((coach) => coach.userId === coachId)?.name || ''}
                                  </div>
                                ))
                              : '-'}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {cohort.userNumber || '0'}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {cohort.isTestCohort ? 'true' : 'false'}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {cohort.removeFromStatistics ? 'true' : 'false'}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {cohort?.config?.accelerometerEnabled ? 'true' : 'false'}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {cohort?.config?.gyroscopeEnabled ? 'true' : 'false'}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {cohort?.config?.audioRecordingEnabled ? 'true' : 'false'}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {cohort?.config?.showBpmResults ? 'true' : 'false'}
                          </td>

                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{cohort.startDate}</td>
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                            <div
                              className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                              onClick={() => navigate(`/${Pages.Cohorts}/report/${cohort.id}`)}
                            >
                              Report
                            </div>
                          </td>

                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                            <div
                              className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                              onClick={() => navigate(`/${Pages.Cohorts}/science/${cohort.id}`)}
                            >
                              Science
                            </div>
                          </td>

                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                            <div
                              className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                              onClick={() => navigate(`/${Pages.Cohorts}/dashboard/${cohort.id}`)}
                            >
                              Stats
                            </div>
                          </td>
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 '>
                            {role === 'admin' && (
                              <div
                                className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                                onClick={() => navigate(`/${Pages.Cohorts}/${cohort.id}`)}
                              >
                                Edit
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CohortList;
