// *******************************************************
// UserScience
// -------------------------------------------------------
// This is a UserScience
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getUserById } from '../../collections/user';
import { TaskItemType } from '../../modeltypes/tasks';
import { useQuery } from '@tanstack/react-query';
import { getRespirationDataByUserId } from 'src/collections/respirationData';
import { UserId } from 'src/modeltypes/id';
import { getSessionDataByUserId } from 'src/collections/session';
import { getScienceDataByUserId } from 'src/collections/scienceData';
import UserRespirationDataList, { UserRespirationDataListType } from './UserRespirationDataList';
import UserScienceDataList, { UserScienceDataListType } from './UserScienceDataList';
import UserSessionList, { UserSessionListType } from './UserSessionList';
import { getAllLessonAudioChunks } from '../../collections/lessonAudioChunk';

import { toast } from 'react-toastify';
// import { prepareCSV, ResearchCSVType } from '../../utility/csvUtility';
import JSZip from 'jszip';
import { StorageReference, getBlob, listAll, ref, uploadBytes } from 'firebase/storage';
import { getStorageRef } from '../../firebase/utils';

import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import BpmPopupChart, { BPmPopupChartType } from '../BpmPopupChart';
import { LessonAudioChunkType } from 'src/modeltypes/lessonAudioChunk';
import { getAllTracks } from 'src/collections/tracks';
import { TrackType } from 'src/modeltypes/tracks';
import { UnitType } from 'src/modeltypes/unit';
import { getAllUnits } from 'src/collections/units';
import { storage } from 'src/firebase/firebaseConfig';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
export type UserScienceElementType = {
  id: string;
  title: string;
  taskItems?: TaskItemType[];
  isPractice?: boolean;
  duration?: number | null;
};

const UserScience = () => {
  const { id } = useParams();

  // For the Respiration Data
  const [showRespiration, setShowRespiration] = useState<boolean>(false);
  const initialData = { node: null, user: null, unit: null, respirationData: null, unitIndex: null };
  const [respirationPopupData, setRespirationPopupData] = useState<UserRespirationDataListType | null>(initialData);

  // For the Respiration Data
  const [showScience, setShowScience] = useState<boolean>(false);
  const initialScienceData = { node: null, user: null, unit: null, scienceData: null, unitIndex: null };
  const [sciencePopupData, setSciencePopupData] = useState<UserScienceDataListType | null>(initialScienceData);

  const [isPreparingSensorsData, setIsPreparingSensorsData] = useState(false);

  const [audioChunks, setAudioChunks] = useState<LessonAudioChunkType[] | null>(null);
  const [tracks, setTracks] = useState<TrackType[] | null>(null);
  const [units, setUnits] = useState<UnitType[] | null>(null);

  // For the Session Data
  const [showSession, setShowSession] = useState<boolean>(false);
  const initialSessionData = {
    node: null,
    user: null,
    unit: null,
    sessionData: null,
    unitIndex: null,
    scienceData: null,
    respirationData: null,
  };
  const [sessionPopupData, setSessionPopupData] = useState<UserSessionListType | null>(initialSessionData);

  const [showBPM, setShowBPM] = useState<boolean>(false);
  const initialBPMData = {
    plot1: null,
  };
  const [bpmPopupData, setBPMPopupData] = useState<BPmPopupChartType | null>(initialBPMData);

  const { data: user, refetch: refetchUser } = useQuery({
    queryFn: () => getUserById(id),
    queryKey: ['user'],
    initialData: null,
  });

  const { data: respData, refetch: refectchRespData } = useQuery({
    queryFn: () => getRespirationDataByUserId(id as UserId),
    queryKey: ['respirationData'],
    initialData: null,
  });

  const { data: seshData, refetch: refectchSessionData } = useQuery({
    queryFn: () => getSessionDataByUserId(id as UserId),
    queryKey: ['sessionData'],
    initialData: null,
  });

  const sortedSesh = seshData?.sort((a, b) => {
    if (a?.createdAt?.toDate && b?.createdAt?.toDate) {
      return b?.createdAt?.toDate()?.getTime() - a?.createdAt?.toDate()?.getTime();
    }
    return -1;
  });

  // console.log('asdf sortedSesh: ', sortedSesh);

  const { data: sciData, refetch: refectchScienceData } = useQuery({
    queryFn: () => getScienceDataByUserId(id as UserId),
    queryKey: ['scienceData'],
    initialData: null,
  });

  console.log('respData: ', respData);
  console.log('sciData: ', sciData);
  console.log('seshData: ', seshData);

  const downloadFile = async (path: StorageReference | null | undefined) => {
    if (path) {
      const blob = await getBlob(path);
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = path.parent?.name || path.name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const toastWrap = (downloadFunction: Promise<void>) => {
    toast.promise(downloadFunction, {
      pending: `Preparing file...`,
      error: "Can't do it now, try again.",
      success: `File is being downloaded!`,
    });
  };

  const downloadZippedSensorsData = async () => {
    setIsPreparingSensorsData(true);

    const userSession = await getSessionDataByUserId(id || '');

    const sessionsWithFiles = userSession?.filter((x) => x.accelFile || x.gyroFile);

    if (sessionsWithFiles?.length) {
      toast.info('Preparing sensors files to be downloaded', {
        toastId: 'sensors',
        autoClose: false,
        closeOnClick: false,
        isLoading: true,
        progress: 0,
      });
      const zip = new JSZip();

      //   const csvData = prepareCSV({
      //     afterSessions,
      //     beforeCCE,
      //     sessions,
      //     users: userDetails?.map((el) => el.account) || null,
      //     timezone: cohort?.timezone?.name,
      //   });

      for (const idx in sessionsWithFiles) {
        const index = +idx;
        toast.update('sensors', { progress: (index + 1) / sessionsWithFiles.length });
        const session = sessionsWithFiles[index];
        const accelerometerRef = session.accelFile ? getStorageRef(session.accelFile) : null;
        const gyroRef = session.gyroFile ? getStorageRef(session.gyroFile) : null;
        const audioRef = session.audioFile ? getStorageRef(session.audioFile) : null;

        if (accelerometerRef) {
          const accelFile = await getBlob(accelerometerRef);
          const accelString = await accelFile.text();
          const accelData = JSON.parse(accelString);
          // const csvSession = csvData?.find((el) => el.sessionId === session.id) || {};
          const fullData = {
            // csv: csvSession,
            accelerometer: accelData,
          };
          console.log('about to get fileWithCsv', session.accelFile);
          const fileWithCsv = await new Blob([JSON.stringify(fullData, null, 20)]);

          const fileBreakdown = session?.accelFile?.split('/');

          const fileName = fileBreakdown?.[fileBreakdown?.length - 2];
          console.log('about to zip file fileWithCsv', session.accelFile);
          try {
            zip.file(`accelerometer/${fileName}-${accelerometerRef.name}`, fileWithCsv, {
              comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
            });
          } catch (error) {
            console.log('Zip Failed for , ', fileName, ' error - ', error);
          }
        } else {
          console.log(`session ${session.id}, doesnt have accel data`);
        }

        if (gyroRef) {
          const gyroFile = await getBlob(gyroRef);
          const gyroString = await gyroFile.text();
          const gyroData = JSON.parse(gyroString);
          // const csvSession = csvData?.find((el) => el.sessionId === session.id) || {};
          const fullData = {
            // csv: csvSession,
            gyroscope: gyroData,
          };
          const fileWithCsv = new Blob([JSON.stringify(fullData, null, 20)]);
          zip.file(`gyroscope/${session.id}-${gyroRef.name}`, fileWithCsv, {
            comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
          });
        } else {
          console.log(`session ${session.id}, doesnt have gyro data`);
        }

        if (audioRef) {
          const audioFile = await getBlob(audioRef);
          zip.file(`audioscope/${session.id}-${audioRef.name}`, audioFile, {
            comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
          });
        } else {
          console.log(`session ${session.id}, doesnt have audio data`);
        }
      }

      if (Object.values(zip.files).length > 0) {
        toast.info('Zipping files...', {
          toastId: 'zipping',
          isLoading: true,
          closeOnClick: false,
        });
        zip
          .generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
              /* compression level ranges from 1 (best speed) to 9 (best compression) */
              level: 9,
            },
          })
          .then(function (blob) {
            saveAs(blob, `${user?.email}-${id}-${new Date().toISOString().replace('T', '/').split('.')[0]}`);
            toast.update('zipping', {
              type: 'success',
              render: 'Downloaded zipped sensors data...',
              closeOnClick: true,
              isLoading: false,
              autoClose: 5000,
            });
          });
      } else {
        toast.update('zipping', {
          type: 'error',
          render: 'There is no sensors files to be downloaded',
          closeOnClick: true,
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
    setIsPreparingSensorsData(false);
  };

  useEffect(() => {
    getAllLessonAudioChunks().then((fTracks) => setAudioChunks(fTracks));
    getAllTracks().then((fTracks) => setTracks(fTracks));
    getAllUnits().then((fUnits) => setUnits(fUnits));
  }, []);

  if (!id) {
    return null;
  }

  return (
    <div>
      <div>
        <p>User:</p>
        <p className='font-bold text-xl'>{user?.email}</p>
        <p className='font-bold text-xl'>{user?.id}</p>

        <p className='font-bold text-xl'>
          {user?.firstName} {user?.lastName}
        </p>
      </div>
      <div className='my-5'>
        <p>Refresh</p>
        <button
          className='ml-2 font-bold text-red-600 text-lg p-20'
          onClick={() => {
            refetchUser();
            refectchRespData();
            refectchSessionData();
            refectchScienceData();
          }}
        >
          Refresh This Beach
        </button>
        <button
          disabled={isPreparingSensorsData}
          className='mt-4 text-indigo-400 hover:text-indigo-700 font-bold'
          onClick={() => downloadZippedSensorsData()}
        >
          Download Sensors data
        </button>
        <button
          className='ml-2 font-bold text-red-600 text-lg p-20'
          onClick={() => {
            console.log('asdfasdf inside dont press this button');
            const listRef = ref(storage, 'gs://equa-health-bsf-data-prod/bsf-analysis');

            listAll(listRef)
              .then((res) => {
                console.log('res: ', res);
                res.prefixes.forEach((folderRef, index) => {
                  if (!index) {
                    console.log('about to copy itemRef.fullPath: ', folderRef.fullPath);

                    // console.log('folderRef.name: ', folderRef.name);
                    // console.log('folderRef.bucket: ', folderRef.bucket);
                    // All the prefixes under listRef.
                    // You may call listAll() recursively on them.
                    listAll(folderRef)
                      .then(async (res) => {
                        console.log('--------------------start-----------------------------: ');
                        console.log('res.items: ', res.items);

                        const newLocation = `gs://equa-health-bsf-data-prod/bsf-analysis`;
                        const newRef = ref(storage, newLocation);
                        res.items.forEach(async (itemRef) => {
                          console.log('itemRef.name: ', itemRef.name);
                          console.log('itemRef.bucket: ', itemRef.bucket);
                          console.log('itemRef?.fullPath: ', itemRef.fullPath);
                          const blob = await getBlob(itemRef);
                          await uploadBytes(newRef, blob);
                          console.log('--------------------done-----------------------------: ');
                        });

                        // console.log('res.name: ', res.name);
                        // console.log('res.bucket: ', res.bucket);
                      })
                      .catch((error) => {
                        console.log('error: ', error);
                      });
                  }
                });
              })
              .catch((error) => {
                console.log('error: ', error);

                // Uh-oh, an error occurred!
              });
          }}
        >
          Dont press this button
        </button>
      </div>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Item</p>
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Created At</p>
                    </th>
                    {/* <th
                              scope='col'
                              className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>
                                User Email
                                <span
                                  className={`ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                                >
                                  <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                                </span>
                              </p>
                            </th>
                            <th
                              scope='col'
                              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                            >
                              <p className='group inline-flex'>User Name</p>
                            </th> */}
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Lesson</p>
                    </th>{' '}
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Lesson Title</p>
                    </th>{' '}
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Lesson Id</p>
                    </th>{' '}
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Accel File Lesson I</p>
                    </th>{' '}
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Path Type</p>
                    </th>{' '}
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Duration</p>
                    </th>{' '}
                    <th scope='col' className='px-3 py-3.5 texst-left text-sm font-semibold text-gray-900'>
                      Time in Lesson (s)
                    </th>
                    <th scope='col' className='px-3 py-3.5 texst-left text-sm font-semibold text-gray-900'>
                      Time in Lesson (min)
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Lesson Id from file</p>
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Accel Data</p>
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Accel BPM (Raw)</p>
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Audio Data (Raw)</p>
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                    >
                      <p className='group inline-flex'>Accel BPM (Smoothed)</p>
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Gryo Data
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      ML Version
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Session Id
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Disturbed
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      <p>Finished Lesson</p>
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      <p>Has Resp Data</p>
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      <p>Has Sci Data</p>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {sortedSesh?.map((session, index) => {
                    const thisLesson = audioChunks?.find((el) => el.id === session?.nodeId) || null;
                    const thisTrack = tracks?.find((el) => el.id === session?.trackId) || null;
                    // console.log('thisTrack', thisTrack);
                    const thisUnit = units?.find((el) => el.id === session?.unitId) || null;
                    const thisUnitElement = thisUnit?.elements?.find((el) => el.id === session?.nodeId);

                    // const regex = /This is([^]*?)sentence/;
                    const regexForUser = /userId-([^]*?)trackId/;
                    const regexForLesson = /lessonId-([^]*?)__/;

                    const fileLocationUserId = session?.accelFile?.match(regexForUser);
                    const fileLocationLessonId = session?.accelFile?.match(regexForLesson);
                    // console.log('fileLocationUserId', fileLocationUserId);
                    // console.log('fileLocationLessonId', fileLocationLessonId);
                    const athis = thisTrack && fileLocationUserId;
                    athis;
                    return (
                      <tr key={session.id} className='hover:bg-stone-200'>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='ml-4'>
                            <div className='text-gray-500'>{index + 1}</div>
                          </div>
                        </td>

                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>
                                {format(session?.createdAt?.toDate() || Date.now(), 'dd MMMM  yy, HH:mm')}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {session?.nameOfSession?.length > 20
                            ? `${session?.nameOfSession.substring(0, 20)}...`
                            : session?.nameOfSession}
                          {/* {session?.nameOfSession} */}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {/* {session?.nameOfSession?.length > 20
                          ? `${session?.nameOfSession.substring(0, 20)}...`
                          : session?.nameOfSession} */}
                          {thisLesson?.title}
                        </td>

                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{session?.nodeId}</td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{fileLocationLessonId}</td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {thisUnitElement?.pathType}
                        </td>

                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{thisLesson?.duration}</td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 `}
                            onClick={() => {
                              // navigate(`/rawData/${session.id}/${session.id}`);
                            }}
                          >
                            {session?.timeInLesson}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div className={`text-indigo-600 `}>
                            {session?.timeInLesson ? (session.timeInLesson / 60).toFixed(1) : '-'}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              session.accelFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              if (session?.accelFile) {
                                const fileName =
                                  session?.accelFile && session?.accelFile?.match('gs://equa-health-bsf-data-prod/')
                                    ? session?.accelFile?.replace(
                                        'equa-health-bsf-data-prod',
                                        'equa-health.appspot.com',
                                      )
                                    : session?.accelFile;
                                console.log('fileName', fileName);
                                const acceloRef = session?.accelFile ? getStorageRef(fileName) : null;
                                toastWrap(downloadFile(acceloRef));
                              }
                            }}
                          >
                            {typeof session?.accelFile === 'string' ? session?.accelFile : '-'}
                            {/* {typeof session?.accelFile === 'string' ? session?.accelFile.substring(0, 10) : '-'} */}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              session.accelFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              if (session?.accelFile) {
                                const acceloRef = session?.accelFile ? getStorageRef(session?.accelFile) : null;
                                toastWrap(downloadFile(acceloRef));
                              }
                            }}
                          >
                            {typeof session?.accelFile === 'string' ? session?.accelFile : '-'}
                            {/* {typeof session?.accelFile === 'string' ? session?.accelFile.substring(0, 10) : '-'} */}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              session.accelPreProcessed ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              if (session?.accelPreProcessed?.length) {
                                setBPMPopupData({
                                  plot1: session?.accelRespirationData,
                                  plot2: session?.accelPreProcessed,
                                  sessionData: session,
                                });
                                setShowBPM(true);
                              }
                            }}
                          >
                            {session?.accelPreProcessed?.length ? 'Yes' : '-'}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              session.accelFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              if (session?.audioFile) {
                                const acceloRef = session?.audioFile ? getStorageRef(session?.audioFile) : null;
                                toastWrap(downloadFile(acceloRef));
                              }
                            }}
                          >
                            {typeof session?.audioFile === 'string' ? session?.audioFile : '-'}
                            {/* {typeof session?.audioFile === 'string' ? session?.audioFile.substring(0, 10) : '-'} */}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              session.accelRespirationData ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              if (session?.accelPreProcessed?.length) {
                                setBPMPopupData({
                                  plot1: session?.accelRespirationData,
                                  plot2: session?.accelPreProcessed,
                                  sessionData: session,
                                });
                                setShowBPM(true);
                              }
                            }}
                          >
                            {session?.accelRespirationData?.length ? 'Yes' : '-'}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              session.gyroFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              if (session?.gyroFile) {
                                const gyrooRef = session?.gyroFile ? getStorageRef(session?.gyroFile) : null;
                                toastWrap(downloadFile(gyrooRef));
                              }
                            }}
                          >
                            {typeof session?.gyroFile === 'string' ? session?.gyroFile.substring(0, 10) : '-'}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div className={`text-indigo-600`}>{session?.mlVersion}</div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div className={`text-indigo-600`}>{session?.id}</div>
                        </td>

                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>{}</div>
                          {typeof session?.disturbanceDetected === 'boolean' ? `${session.disturbanceDetected}` : '-'}
                        </td>

                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>{`${session?.lessonFinished}`}</div>
                        </td>

                        {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-500'>
                                  {`${respirationData?.some((x) => x.sessionId === session?.id)}`}
                                </div>
                              </td> */}

                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>
                            {/* {`${sciencePopupData?.some((x) => x.sessionId === session?.id)}`} */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <button
        className='mt-5 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        onClick={() => {
          return null;
        }}
      ></button>
      {showRespiration ? (
        <UserRespirationDataList
          {...respirationPopupData}
          onPressClose={() => {
            setShowRespiration(false);
            setRespirationPopupData(initialData);
          }}
        />
      ) : null}

      {showScience ? (
        <UserScienceDataList
          {...sciencePopupData}
          onPressClose={() => {
            setShowScience(false);
            setSciencePopupData(initialScienceData);
          }}
        />
      ) : null}
      {showSession ? (
        <UserSessionList
          {...sessionPopupData}
          onPressClose={() => {
            setShowSession(false);
            setSessionPopupData(initialSessionData);
          }}
        />
      ) : null}

      {showBPM ? (
        <BpmPopupChart
          {...bpmPopupData}
          onPressClose={() => {
            setShowBPM(false);
            setBPMPopupData(initialBPMData);
          }}
        />
      ) : null}
    </div>
  );
};

export default UserScience;
